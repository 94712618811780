import React, { createContext, useState, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getPaymentRequest, submitPayment as submitPaymentRequest } from './api';
import { PaymentRequest } from './payment-request';

type SubmitResult = 'success' | 'error';

const mockedToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE3ZGExNmRmLWFlNTYtNDI4YS05MmM2LTgwN2RjOGIzNDdlNCJ9.eyJpc3MiOiJodHRwczpcL1wvYnJva2VyYXBpLnhjaGFuZ2VwbHVzLmRldlwvIiwianRpIjoiYTdkYTE2ZGYtYWU1Ni00MjhhLTkyYzYtODA3ZGM4YjM0N2U0IiwiaWF0IjoxNjIzMzIxNzE2LCJuYmYiOjE2MjMzMjE3MTYsImV4cCI6MTYyNTEzNjExNiwic2l0ZSI6ImJyb2tlci54Y2hhbmdlcGx1cy5kZXYiLCJlbWFpbCI6ImdldmNodWtAdGVhbWhhcmJvdXIuY29tIiwidG9rZW4iOiJwYXltZW50X3Rva2VuIn0.maEqGn2cJfw3IZYFoIh2pIgALj1kpNVVbCBXPEr2XmBwJtA9O_6KXvhL6ieR6_hKcpMTBjP-1m84yUBXtPL8uL8G9NUQg_0oIpQIe9hfb3mWxmU0zIPUDdZgebTUsWd64sZbbrnEZe4tVABmlUD8XT7l27Y5u147Yk7BW-y8ldtn5MBjsF5L1yQQgcxC3ZDvt-EWv0dKDkbJMKRUa05X5pnzHbkeX-hU27O9Xi2HBhcJ-GEdvwg_qydLUaweIIJ6PmmwVEU83Vg0tcjiyzr-lywvExrBxkcT2epL80TXkA634hlgmhF64g5_X6RFzkzbpDJ0koyv2tsdlW1FJZSWXQ';

type ContextValues = {
  paymentRequest?: PaymentRequest;
  submitResult?: SubmitResult;
  setSubmitResult: React.Dispatch<React.SetStateAction<SubmitResult | undefined>>;
  submitPayment: (values: Object) => void;
  token: string;
};

const Context = createContext({} as ContextValues);

const Provider = ({ children }) => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>();
  const [submitResult, setSubmitResult] = useState<SubmitResult | undefined>();
  const [token] = useQueryParam('token', StringParam);

  const submitPayment = async (values) => {
    try {
      await submitPaymentRequest(values);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
      setSubmitResult('error');
    }
  };

  useEffect(() => {
    if (!token) return setSubmitResult('error');

    (async () => {
      try {
        const paymentRequest = await getPaymentRequest(token);
        setPaymentRequest(paymentRequest);
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
        setSubmitResult('error');
      }
    }
    )();
  }, [token]);

  const contextValues: ContextValues = {
    paymentRequest,
    submitResult,
    setSubmitResult,
    submitPayment,
    token
  };

  return (
    <Context.Provider value={contextValues}>
      {children}
    </Context.Provider>
  );
};

export { Provider };

export default Context;



