import React from 'react';
import NoSSR from 'components/NoSSR';
import PaymentRequestPage from 'views/PaymentRequestPage';

const PaymentRequest = () => {
  return (
    <NoSSR>
      <PaymentRequestPage />
    </NoSSR>
  );
};

export default PaymentRequest;
