import React, { useContext } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';
import Context from './PaymentRequestContext';

const PaymentRequestInfo = () => {
  const { paymentRequest } = useContext(Context);

  if (!paymentRequest) return null;

  return (
    <StyledPaymentRequestInfo className="request-info">
      <tbody>
        <tr>
          <td>Number</td>
          <td>{paymentRequest.receiptNumber}</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{dayjs.unix(paymentRequest.createdOn).format('DD MMMM YYYY, hh:mm A')}</td>
        </tr>
        <tr>
          <td>Property</td>
          <td>{paymentRequest.propertyAddress || 'N/A'}</td>
        </tr>
        <tr>
          <td>Service</td>
          <td>
            {paymentRequest.service || 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Requester</td>
          <td>{paymentRequest.requester || 'N/A'}</td>
        </tr>
        <tr>
          <td><strong>Amount</strong></td>
          <td><strong>{formatCurrency(paymentRequest.amount)}</strong></td>
        </tr>
      </tbody>
    </StyledPaymentRequestInfo>
  );
};

export default React.memo(PaymentRequestInfo);

const StyledPaymentRequestInfo = styled.table`
  width: 100%;
  border-collapse: collapse;
  flex-grow: 1;
  max-width: 475px;
  font-size: 14px;
  line-height: 18px;
  td {
    padding: 20px 18px;
    border: 1px solid #eeeeee;
    &:first-of-type {
      color: ${props => props.theme.colors.grayDark};
    }
    &:last-of-type {
      padding: 20px 30px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    td {
      padding: 15px 16px;
      &:last-of-type {
        padding: 15px 20px;
      }
    }
  }
`;
