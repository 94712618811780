import { fetch } from 'utils/fetch';
import { urlEncodeBody, checkSuccess } from 'utils/requests';
import { PaymentRequest } from './payment-request';

export async function getPaymentRequest(token: string): Promise<PaymentRequest> {
  const response = await fetch(`/transactions/${token}`);
  const data = await response.json();

  checkSuccess(data);

  return {
    receiptNumber: data.receipt_number,
    createdOn: data.created_on,
    propertyAddress: data.order_detailed?.package_description,
    service: data.name,
    requester: data.requester,
    amount: data.amount,
    tokenCharged: data.token_charged
  };
}

export async function submitPayment({
  token,
  stripeAccount,
  paymentMethod
}: {
  token: string;
  stripeAccount: string;
  paymentMethod: object;
}): Promise<void> {
  await fetch(`/transactions/charge`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, stripeAccount, paymentMethod })
  });
}

export async function sendReceipt({
  token,
  email
}: {
  token: string;
  email: string;
}): Promise<void> {
  await fetch(`/transactions/send`, {
    method: 'POST',
    body: urlEncodeBody({ token, email })
  });
}
