import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardNumberElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { info } from 'sitedata';
import notify from 'notify';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Input from 'components/Input';
import StripeInput from 'components/StripeInput';

import { REGEXP } from 'consts';
import Context from './PaymentRequestContext';
import { submitPayment } from './api';

const stripeKey = info.GATSBY_STRIPE_KEY || '';
const stripeAccount = info.GATSBY_STRIPE_ACCOUNT || '';

const PaymentRequestForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { setSubmitResult, token } = useContext(Context);

  const stripeLoaded = Boolean(stripe && elements);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors
  } = useForm();
  const [disablePayment, setDisablePayment] = useState<boolean>(false);

  const submit = async () => {
    try {
      setDisablePayment(true);
      if (!stripe || !elements) throw Error('Stripe is not loaded');
      const cardNumber = elements.getElement(CardNumberElement);
      if (!cardNumber) throw Error('No card number element');

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {}
      });
      if (error) {
        // eslint-disable-next-line no-console
        console.error('[stripe]', error);
        throw Error(error.message);
      }

      if (!paymentMethod) {
        setSubmitResult('error');
        return;
      }

      await submitPayment({
        token,
        paymentMethod,
        stripeAccount
      });
      setSubmitResult('success');
    } catch (err) {
      notify(err.message);
    } finally {
      setDisablePayment(false);
    }
  };

  const handleStripeInputChange = e => {
    if (e.error) {
      setError(e.elementType, { type: 'stripe', message: e.error.message });
    } else {
      clearErrors(e.elementType);
    }
  };

  return (
    <StyledPaymentRequestForm className="payment-form" onSubmit={handleSubmit(submit)}>
      <h2>Pay</h2>
      <div className="payment-form__row">
        <StripeInput
          name="cardNumber"
          type="number"
          label="Card Number"
          className="payment-form__item payment-form__number"
          placeholder="Enter Card Number"
          error={errors.cardNumber?.message}
          onChange={handleStripeInputChange}
        />
      </div>
      <div className="payment-form__row">
        <StripeInput
          type="expiry"
          label="Expiration Date"
          className="payment-form__item payment-form__expiry"
          placeholder="MM/YY"
          error={errors.cardExpiry?.message}
          onChange={handleStripeInputChange}
        />
        <StripeInput
          type="cvc"
          label="CVC"
          placeholder="000"
          className="payment-form__item payment-form__cvc"
          error={errors.cardCvc?.message}
          onChange={handleStripeInputChange}
        />
        <Input
          {...register('zipcode', {
            pattern: { value: REGEXP.ZIP_CODE, message: 'Invalid ZIP' }
          })}
          name="zipcode"
          label="ZIP"
          placeholder="00000"
          className="payment-form__item payment-form__zip"
          data-cy="input_zip"
        />
      </div>
      <Button className="payment-form__btn" disabled={!stripeLoaded || disablePayment}>
        Pay
      </Button>
    </StyledPaymentRequestForm>
  );
};

export default props => (
  <Elements stripe={loadStripe(stripeKey, { stripeAccount })}>
    <PaymentRequestForm {...props} />
  </Elements>
);

const StyledPaymentRequestForm = styled.form`
  padding: 25px 35px 40px 35px;
  border: 1px solid ${props => props.theme.colors.seashell};
  border-radius: ${props => props.theme.misc.borderRadius};
  .payment-form {
    &__row {
      display: flex;
      margin: 0 0 16px;
    }
    &__item ~ * {
      margin-left: 12px;
    }
    &__number {
      width: 100%;
    }
    &__expiry,
    &__cvc {
      width: 93px;
    }
    &__zip {
      width: 126px;
    }
    &__btn {
      width: 100%;
      height: 40px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 16px;
    padding: 25px 16px 40px 16px;

    .payment-form {
      &__expiry,
      &__cvc,
      &__zip {
        width: auto;
        flex: 1;
      }
    }
  }
`;
