import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';

import { Button, Icon, Input } from 'components';
import Context from './PaymentRequestContext';
import { sendReceipt } from './api';

const SendReceiptForm = ({ onClickGoBack }) => {
  const { token, setSubmitResult } = useContext(Context);
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit
  } = useForm();

  const submit = async ({ email }) => {
    try {
      await sendReceipt({ email, token });
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
      setSubmitResult('error');
    }
  };

  return isSubmitSuccessful ? (
    <>
      <div className="circle success">
        <Icon name="checkmark" />
      </div>
      <p>Receipt is successfully sent.</p>
      <Button simple onClick={onClickGoBack}>
        Go Back
      </Button>
    </>
  ) : (
    <StyledSendReceiptForm onSubmit={handleSubmit(submit)}>
      <h2>Send to email</h2>
      <Input
        {...register('email', {
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        })}
        label="Email"
        placeholder="Enter Email"
        error={errors.email?.message}
      />
      <Button>Send</Button>
      <Button simple onClick={onClickGoBack}>
        Go Back
      </Button>
    </StyledSendReceiptForm>
  );
};

export default React.memo(SendReceiptForm);

const StyledSendReceiptForm = styled.form`
  align-self: normal;
  h2 {
    text-align: center;
  }
  .input {
    width: 100%;
  }
  .button.simple {
    width: 100%;
  }
`;
