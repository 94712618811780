import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import { info } from 'sitedata';
import { downloadFile } from 'utils/requests';
import { Button, HeaderOnlyLayout, Icon } from 'components';
import Context, { Provider } from './PaymentRequestContext';
import PaymentRequestInfo from './PaymentRequestInfo';
import PaymentRequestForm from './PaymentRequestForm';
import SendReceiptForm from './SendReceiptForm';

const PaymentRequestPage = () => {
  const { submitResult, token, paymentRequest } = useContext(Context);
  const [showSendEmail, setShowEmail] = useState<boolean>(false);

  const getContent = () => {
    if (showSendEmail) {
      return (
        <StyledPaymentMessage className="payment-message">
          <SendReceiptForm onClickGoBack={() => setShowEmail(false)} />
        </StyledPaymentMessage>
      );
    }
    if (submitResult === 'success' || paymentRequest?.tokenCharged) {
      return (
        <StyledPaymentMessage className="payment-message">
          <div className="circle success">
            <Icon name="checkmark" />
          </div>
          <p>Payment is successfully made. Please check your email for further details.</p>
          <p>
            <span className="muted">Receipt Number:</span> {paymentRequest?.receiptNumber}
          </p>
          <Button onClick={() => downloadFile(`${info.apiUrl}/transactions/download/${token}`)}>
            Download Receipt
          </Button>
          <Button simple onClick={() => setShowEmail(true)}>
            Send to different email
          </Button>
        </StyledPaymentMessage>
      );
    }
    if (submitResult === 'error') {
      return (
        <StyledPaymentMessage className="payment-message">
          <div className="circle error">
            <Icon name="mini-x" />
          </div>
          <p>Something went wrong. Please try again</p>
          <Button onClick={() => window.location.reload()}>Go Back</Button>
        </StyledPaymentMessage>
      );
    }
    return paymentRequest ? (
      <>
        <h1>Payment Request</h1>
        <div className="well">
          Your vendor has requested an authorization for the below payment. By pressing Pay, you
          authorize this payment and agree to MyBrokerTools basic payment terms and conditions.
        </div>
        <div className="payment-content">
          <PaymentRequestInfo />
          <PaymentRequestForm />
        </div>
      </>
    ) : null;
  };

  return (
    <HeaderOnlyLayout>
      <StyledPaymentRequestPage className="payment-page">{getContent()}</StyledPaymentRequestPage>
    </HeaderOnlyLayout>
  );
};

export default props => (
  <Provider>
    <PaymentRequestPage {...props} />
  </Provider>
);

const StyledPaymentRequestPage = styled.div`
  margin: auto;
  max-width: 998px;

  h1 {
    margin: 0 0 21px;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .well {
    margin-bottom: 32px;
    padding: 17px 20px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 16px;
    background-color: ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .payment-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 24px 0;

    .payment-content {
      flex-direction: column-reverse;
    }
  }
`;

const StyledPaymentMessage = styled.div`
  margin: auto;
  padding: 30px;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid ${props => props.theme.colors.seashell};
  border-radius: ${props => props.theme.misc.borderRadius};
  .circle {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &.success {
      border: 1px solid ${props => props.theme.colors.lightGreen};
      .icon {
        fill: ${props => props.theme.colors.lightGreen};
      }
    }
    &.error {
      border: 1px solid ${props => props.theme.colors.red};
      .icon {
        fill: ${props => props.theme.colors.red};
      }
    }
  }
  p {
    margin: 10px 0;
    text-align: center;
  }
  .button {
    margin-top: 36px;
    width: 100%;
    height: 40px;
    display: block;
    &:first-of-type {
      margin-top: 36px;
    }
    &.simple {
      margin-top: 23px;
      color: ${props => props.theme.colors.red};
    }
  }
`;
